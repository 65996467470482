import Vue from 'vue'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin, NavbarPlugin } from 'bootstrap-vue'
import VueSwal from 'vue-swal'
import Toasted from 'vue-toasted'
import JsonViewer from 'vue-json-viewer'
import router from './router'
import App from './App.vue'
import axios from './axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/scss/main.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(NavbarPlugin)
Vue.use(VueAxios, axios)
Vue.use(VueSwal)
Vue.use(Toasted)
Vue.use(JsonViewer)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
