<template>
  <b-overlay
    v-if="showOverlay"
    :show="showOverlay"
    class="container"
  />
  <layout v-else />
</template>

<script>
import Layout from '@/layouts/Layout.vue'

export default {
  name: 'App',
  components: {
    Layout,
  },
  data() {
    return {
      showOverlay: true,
    }
  },
  async mounted() {
    // Auth screens
    if (window.location.pathname === '/login' || window.location.pathname === '/callback') {
      this.showOverlay = false
      return
    }

    const res = await this.axios.get(
      `${process.env.VUE_APP_API_URL}/auth/`,
    )
    if (res.status === 200) this.showOverlay = false
  },
}
</script>

<style>
* {
  line-height: 1.7em;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #21272a;
}

.table td {
  vertical-align: middle !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f4f8 !important;
}

.jv-code {
  font-family: 'JetBrains Mono', monospace !important;
  font-size: 13px;
  line-height: 1.7em;
}

.jv-container.jv-light .jv-key {
  color: #21272a !important;
}

input[type="checkbox"].custom-control-input {
  display: none;
}

</style>
