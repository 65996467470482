<template>
  <div
    class="container"
    style="max-width:2000px"
  >
    <navbar v-if="displayNav" />
    <slot />
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      displayNav: true,
    }
  },
  mounted() {
    if (window.location.pathname === '/login' || window.location.pathname === '/callback') this.displayNav = false
  },
}
</script>
