import axios from 'axios'

axios.defaults.withCredentials = true
// Interceptor to force re-enter token
axios.interceptors.response.use(
  response => response,
  error => {
    // Authentication error
    if (error.response.status === 401) {
      localStorage.setItem('ComingPage', window.location.href)
      window.location.href = '/login'
    }
    // Rate Limit error
    if (error.response.status === 429) {
      alert(
        'You have reached your rate limit. Please wait a few minutes to try again.',
      )
    }
    return error
  },
)

export default axios
