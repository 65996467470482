import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'all_companies',
      component: () => import('@/views/CompanyList.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/callback',
      name: 'callback',
      component: () => import('@/views/Callback.vue'),
    },
    {
      path: '/search/',
      name: 'search_companies',
      component: () => import('@/views/CompanySearch.vue'),
    },
    {
      path: '/utms/search/',
      name: 'utm_search_companies',
      component: () => import('@/views/UTMSearch.vue'),
    },
    {
      path: '/company/:companyKey',
      name: 'company',
      component: () => import('@/views/Company.vue'),
    },
    {
      path: '/company/:companyKey/json',
      name: 'company_json',
      component: () => import('@/views/CompanyJson.vue'),
    },
    {
      path: '/company/:companyKey/swap',
      name: 'company_swap',
      component: () => import('@/views/CompanySwap.vue'),
    },
    {
      path: '/user/:userId/json',
      name: 'user_json',
      component: () => import('@/views/UserJson.vue'),
    },
    {
      path: '/sole-installs',
      name: 'sole_installs',
      component: () => import('@/views/SoleInstalls.vue'),
    },
    {
      path: '/integrations',
      name: 'integrations',
      component: () => import('@/views/Integrations.vue'),
    },
    {
      path: '/surveys',
      name: 'surveys',
      component: () => import('@/views/Surveys.vue'),
    },
    {
      path: '/upload',
      name: 'upload_leads',
      component: () => import('@/views/Upload.vue'),
    },
    {
      path: '/tokens',
      name: 'tokens',
      component: () => import('@/views/Tokens.vue'),
    },
    {
      path: '/seatLogs',
      name: 'seatLogs',
      component: () => import('@/views/SeatLogs.vue'),
    },
    {
      path: '/missing-payments',
      name: 'missingPayments',
      component: () => import('@/views/payments/MissingPayments.vue'),
    },
    {
      path: '/feature-flags',
      name: 'featureFlags',
      component: () => import('@/views/FeatureFlags.vue'),
    }
  ],
})

export default router
